//Imports
import React from "react"
import { useStaticQuery, graphql } from "gatsby"

//module
import Img from "gatsby-image"
import Footer from "../components/Footer"

const Layout = ({ className, children }) => {

  const images = useStaticQuery(graphql`
    query {
      wallbackground: file(relativePath: { eq: "background/bricks_bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000,quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      wallbackgroundMobile: file(relativePath: { eq: "background/bricks_bg_mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sources = [
    images.wallbackgroundMobile.childImageSharp.fluid,
    {
      ...images.wallbackground.childImageSharp.fluid,
      media: `(min-width: 480px)`,
    },
  ]

  return (
    <div className={className}>
      {/* <Header /> */}
      <div className={`wallBackground`}>
          <Img fluid={sources} objectFit="cover" objectPosition="50% 50%"/>
      </div>
      <main className={`main`}>
        {children}
      </main>
      <Footer />
    </div>
  )
}

export default Layout
