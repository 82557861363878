//imports
import React, { useRef,useState } from "react";

//svgImport
import logo from '../assets/logo.svg';
import twitter from '../assets/twitter.svg';
import facebook from '../assets/facebook.svg';
import linkedin from '../assets/linkedin.svg';

//styles
import footerStyles from "./sections/sectionsStyles/footerStyles.module.scss"

const Footer = () =>{

  let txtareaRef = useRef();
  const [txtAreaValue, setTxtAreaValue] = useState('https://hubs.la/H0Dg9kK0');

  const copyLink = () => {
    // console.log(txtareaRef.current.value)
    const context = txtareaRef.current;
    context.select();
    document.execCommand("copy");
    setTxtAreaValue('lien copié!')
    // context.select();
  }

  return (
    <footer className={`component ${footerStyles.component}`}>

      <div className={`container ${footerStyles.footerContainer}`}>
          <div className={`row flex-row-reverse` }>

              <div className={`col-12 col-lg-7 ${footerStyles.shareEventCol}`}>
                <div className={`${footerStyles.shareEventInnerWrapper}`}>
                  <div className="row">
                    <div className={`col-12 col-lg-5 ${footerStyles.socialShare}`}>
                    <p className={`h2Style`}>Partager l’évenement</p>
                    <ul className={`d-flex flex-row align-items-center`}>
                      <li><a href={"https://twitter.com/intent/tweet"} target="_blank" rel="noreferrer"><img src={twitter} alt="share page on twitter" className={`d-block img-fluid`}/></a></li>
                      <li><a href={"https://www.facebook.com/sharer/sharer.php?u=https://content-summit-2021.playplay.com/"} target="_blank" rel="noreferrer"><img src={facebook} alt="share page on facebook" className={`d-block img-fluid`}/></a></li>
                      <li><a href={"http://www.linkedin.com/shareArticle?mini=true&url=https://content-summit-2021.playplay.com/"} target="_blank" rel="noreferrer"><img src={linkedin} alt="share page on linkedin" className={`d-block img-fluid`}/></a></li>
                    </ul>
                  </div>
                    <div className={`col-12 col-lg-7 d-lg-flex flex-lg-column justify-content-lg-end align-items-lg-end ${footerStyles.copyLink}`}>
                      <p className={`d-block`}>
                        <span 
                        onClick={copyLink} 
                        onKeyDown={copyLink} 
                        role = "button" 
                        aria-label="Copy Link"
                        tabIndex={0} 
                        >Copier le lien</span>
                        <textarea
                        ref={txtareaRef}
                        readOnly
                        value={txtAreaValue}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`col-12 col-lg-4 ${footerStyles.footerMenuCol}`}>
                <p className={`text-center text-lg-left ${footerStyles.footerplayplayDesc}`}>Playplay est l'outil de création vidéo qui permet aux équipes communication et marketing de créer facilement de magnifiques vidéo en quelques minutes.</p>
                <p className={`bold text-center text-lg-left`}>Découvrez Playplay</p>
                <a href="https://playplay.com" className={`d-block text-center text-lg-left`}>Playplay.com</a>

                {/* <p className={`bold text-center text-lg-left`}>Suivez nous</p> */}
                {/* <ul className={`d-flex flex-row align-items-center justify-content-center justify-content-lg-start`}>
                  <li><a href={"https://playplay.com"}><img src={twitter} alt="playplay twitter"/></a></li>
                  <li><a href={"https://playplay.com"}><img src={facebook} alt="playplay twitter"/></a></li>
                  <li><a href={"https://playplay.com"}><img src={linkedin} alt="playplay twitter"/></a></li>
                </ul> */}
              </div>

              <div className={`col-12 col-lg-1 ${footerStyles.logoCol}`}>
                <div className={`text-center text-lg-left ${footerStyles.logowrapper}`}>
                  <a href="https://playplay.com" className={`d-inline-block`}><img src={logo} className={`d-block img-fluid`} alt="Logo Playplay"/></a>
                </div>
              </div>

          </div>
      </div>


      <div className={`container-fluid ${footerStyles.copyrightContainer}`}>
        <div className={`row ${footerStyles.copyrightRow}` }>
          <div className={`container`}>
            <div className={`row`}>
              <div className={`col-12`}>
                  <p className={`text-center`}>©2020 PlayPlay Tous droits réservés. Mentions légales. Conditions générales de ventes. Politique de confidentialité</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer